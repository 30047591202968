import Actions from "modules/actions/fee-schedule-action";
import AssociatedAccountsTable from "../../shared/AssociatedAccountsTable";

export default {
  name: "FeeSchedAssocModal",
  components: {
    AssociatedAccountsTable
  },
  props: {
    feeSched: {
      type: Object,
      required: true,
    },
    associated: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    __Associated: {
      get() {
        return this.associated;
      },
      set(value) {
        this.$emit("update:associated", value);
      },
    },
    __Dialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },

  data() {
    return {
      actions: new Actions(),
      isLoading: false,
      confirmModal: false,
    };
  },
  methods: {
    processCollectionAssoc() {
      let collectionIds = [];
      this.__Associated.forEach((association) => {
        collectionIds.push(association.id);
      });

      const assocParam = {
        ids: collectionIds,
        associationType: "EXCLUDE_ALL",
      };

      return {
        updateAssociation: {
          association: {
            type: "VbsFeeScheduleAssociationInput",
            value: assocParam,
            unincludeToFields: true,
          },
          mutationReturn: ["success"],
        },
      };
    },

    async onSubmit() {
      let collectionQueries = [];
      this.confirmModal = false;
      try {
        this.isLoading = true;
        collectionQueries.push(this.processCollectionAssoc());
        let params = {
          variables: {
            id: {
              type: "UUID!",
              value: this.feeSched.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: collectionQueries,
        };
        await this.actions.updateFeeSchedule(params);
        this.showNotifyMessage({
          message: `Accounts associated with this Fee Schedule have been unlinked.`,
          type: "success",
        });
        this.isLoading = false;
        this.__Dialog = false;

        this.$emit("onSuccess");
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while updating Fee Schedule status.",
          type: "danger",
        });
        this.isLoading = false;
      }
    },
  },
};
