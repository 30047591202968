import Actions from "modules/actions/fee-schedule-action";

export default {
  name: "AssociatedAccountsTable",
  props: {
    feeSched: {
      type: Object,
      required: true,
    },
    associated: {
      type: Array,
      required: true,
    },
  },
  computed: {
    __Associated: {
      get() {
        return this.associated;
      },
      set(value) {
        this.$emit("update:associated", value);
      },
    },

    totalCount() {
      return this.__Associated.length;
    },

    headers() {
      return this.feeSched.type.enum == "PAYOR" ||
        this.feeSched.type.value == "PAYOR"
        ? this.payorColumns
        : this.clientColumns;
    },
    noDataText() {
      return `There is no ${this.feeSched.type.text.toLowerCase()} associated with this Fee Schedule`;
    },
  },

  data() {
    return {
      actions: new Actions(),
      clientColumns: [
        {
          text: "Client Name",
          align: "left",
          field: "payorName",
          sortable: false,
        },
        {
          text: "Client ID",
          align: "left",
          field: "payorCode",
          sortable: false,
        },
      ],
      payorColumns: [
        {
          text: "Payor Name",
          align: "left",
          field: "name",
          sortable: false,
        },
        {
          text: "Payor Acronym",
          align: "left",
          field: "insuranceCompanyId",
          sortable: false,
        },
      ],
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortOptions: {
          sortBy: "",
          sort: "",
          isDateColumn: false,
        },
      },
      perPageOptions: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "20",
          value: 20,
        },
      ],
    };
  },
  methods: {
    totalPages(itemsPerPage) {
      const hasRemainder = this.totalCount % itemsPerPage != 0;
      return (
        Math.floor(this.totalCount / itemsPerPage) + (hasRemainder ? 1 : 0)
      );
    },
    disablePaginationButton(button = "first") {
      if (button.toUpperCase() === "FIRST") {
        return this.paginationOptions.page === 1;
      } else {
        return (
          this.paginationOptions.page ===
          this.totalPages(this.paginationOptions.itemsPerPage)
        );
      }
    },
    async firstPage() {
      this.paginationOptions.page = 1;
    },
    async lastPage(itemsPerPage) {
      const totalPages = this.totalPages(itemsPerPage);
      this.paginationOptions.page = totalPages;
    },
  },
};
