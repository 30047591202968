var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-searchfilter',{ref:"searchFilter",attrs:{"options":_vm.searchFilter.fields,"modelInstance":_vm.searchFilter.model,"columns":_vm.searchFilter.columns,"limitData":_vm.limitData,"limit":10,"isLoading":_vm.isDataLoading,"relativeUrl":"/fee-schedule/get-all","queryName":"vbsFeeSchedules","hasArchiveText":"Include Deactivated","hasArchive":"","hideDeleted":"","allowFutureDates":""},on:{"searchResults":_vm.searchResults,"loading":_vm.loading,"resetDataTableOptions":_vm.resetDataTableOptions}})],1)],1),_c('v-row',[_c('v-col',[_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"addLabel":"Add Fee Schedule","orderBy":_vm.orderBy,"data":_vm.filteredFeeSchedule,"columns":_vm.columns,"row-key":"id","filter":_vm.filter,"isLoading":_vm.isDataLoading,"initialLoading":_vm.initialDataLoading,"noDataLabel":"No Fee Schedule found","flatx":"","totalCount":_vm.totalCount,"searchFilter":_vm.$refs.searchFilter,"bordered":"","withAdd":"","hideDelete":true,"hideView":true,"hasChangeStatus":"","editLabel":"View/Edit","accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasDeleteAccessControl: true,
        }},on:{"onAdd":_vm.addFeeSchedule,"onEdit":_vm.editFeeSchedule,"onChangeStatus":_vm.toggleChangeStatus}})],1)],1),_c('FeeSchedAssocModal',{attrs:{"dialog":_vm.feeSchedAssocDialog,"associated":_vm.viewAssociation,"feeSched":_vm.viewSelectedFeeSched},on:{"update:dialog":function($event){_vm.feeSchedAssocDialog=$event},"onSuccess":_vm.resetStatusForm}}),_c('v-change-status',{attrs:{"visible":_vm.changeStatusForm.isVisible,"id":_vm.changeStatusForm.id,"isActive":_vm.changeStatusForm.isActive,"isDisabled":_vm.changeStatusForm.isDisabled,"hasWarningAction":_vm.hasWarningDialog,"warningActionText":"View List","header":"Change Status","message":"You are about to change the status of this account.","warning":"Changing the status will update the Termination Date to today's date and the deactivation will only take effect after the termination date.","primaryWarning":"Currently this fee schedule is associated with the following accounts:","buttonTexts":{
      cancel: 'Cancel',
      activate: 'Activate',
      deactivate: 'Deactivate',
    }},on:{"close":function($event){return _vm.handleChangeStatusVisibility(false)},"submit":_vm.onSubmitChangeStatus,"warningAction":_vm.onViewFeeSchedAssoc}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }