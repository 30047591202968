import FeeSchedule from "store/models/FeeSchedule";
import FeeSchedAssocModal from "./modals/FeeSchedAssociation";
import Actions from "modules/actions/fee-schedule-action";
import capitalize from "lodash/capitalize";

const actions = new Actions();

export default {
  name: "FeeSchedules",
  components: {
    FeeSchedAssocModal,
  },
  data() {
    return {
      isLoading: false,
      filter: "",
      orderBy: {
        feeId: "orderByFeeId",
        name: "orderByName",
        scheduleType: "orderByType",
        adjustmentType: "orderByAdjustmentType",
        status: "orderByValidityStatus",
        effectivityDate: "orderByEffectivityDate",
        terminationDate: "orderByTerminationDate",
      },
      feeSchedules: [],
      isDataLoading: false,
      initialDataLoading: false,
      columns: [
        {
          name: "feeId",
          align: "left",
          text: "Fee Schedule ID",
          field: "feeId",
          sortable: true,
        },
        {
          name: "name",
          align: "left",
          text: "Fee Schedule Name",
          field: "feeName",
          sortable: true,
        },
        {
          name: "scheduleType",
          align: "left",
          text: "Fee Schedule Type",
          field: "scheduleType",
          sortable: true,
        },
        {
          align: "left",
          name: "adjustmentType",
          text: "Adjustment Type",
          field: "adjustmentType",
          sortable: true,
        },
        {
          name: "effectivityDate",
          align: "left",
          text: "Effective Date",
          field: "effectivityDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
          sortableByDate: true,
        },
        {
          name: "terminationDate",
          align: "left",
          text: "Termination Date",
          field: "terminationDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
          sortableByDate: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          sortable: true,
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 3,
        model: FeeSchedule,
        fields: [
          {
            label: "Fee Schedule ID",
            name: "feeId",
            type: "text",
            maxlength: 10,
          },
          {
            label: "Fee Schedule Name",
            name: "name",
            type: "text",
            maxlength: 50,
          },
          {
            label: "Adjustment Type",
            name: "adjustmentType",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: [
              { label: "Percentage Discount", value: "PERCENTAGE_DISCOUNT" },
              { label: "Flat Rate", value: "FLAT_RATE" },
              { label: "Standard Rate", value: "STANDARD" },
              { label: "None", value: "NONE" },
            ],
          },
          {
            label: "Fee Schedule Type",
            name: "type",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: [
              { label: "Client", value: "CLIENT" },
              { label: "Payor", value: "PAYOR" },
              { label: "Self Pay", value: "SELF_PAY" },
              { label: "Other", value: "OTHER" },
            ],
          },
          {
            label: "Effective Date",
            name: "effectivityDate",
            type: "date",
            format: "MM/DD/YYYY",
          },
          {
            label: "Termination Date",
            name: "terminationDate",
            type: "date",
            format: "MM/DD/YYYY",
          },
        ],
      },
      limitData: [
        "name",
        "feeId",
        this.buildSubQuery("type", ["enum", "label"], false),
        this.buildSubQuery("adjustmentType", ["enum", "label"], false),
        this.buildSubQuery("status", ["enum", "label"], false),
        "effectivityDate",
        "terminationDate",
        "totalCount",
      ],
      totalCount: 0,
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      feeSchedAssocDialog: false,
      viewAssociation: [],
      viewAssocType: "Client",
      viewSelectedFeeSched: {
        feeId: "",
        name: "",
      },
    };
  },
  computed: {
    hasWarningDialog() {
      if (this.viewAssociation.length == 0) return false;
      return this.viewAssocType === "Client" || this.viewAssocType === "Payor"
        ? true
        : false;
    },
    filteredFeeSchedule() {
      try {
        const data = this.feeSchedules.map((item) => ({
          id: item.id,
          feeId: item.feeId || null,
          scheduleType: item.type ? capitalize(item.type.label) : null,
          adjustmentType: item.adjustmentType?.label || null,
          status: item.status?.label || null,
          feeName: item.name,
          effectivityDate: item.effectivityDate
            ? this.$options.filters.getTimeDate(
                item.effectivityDate,
                "MM/DD/YYYY"
              ).zone
            : null,
          terminationDate:
            item.terminationDate && item.terminationDate.toUpperCase() != "NONE"
              ? this.$options.filters.getTimeDate(
                  item.terminationDate,
                  "MM/DD/YYYY"
                ).zone
              : null,
          isActive: item.status?.label.toUpperCase() === "ACTIVE",
        }));
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    loading(bool) {
      this.isDataLoading = bool;
    },
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.feeSchedules = result;
    },
    addFeeSchedule() {
      this.$router.push("/system-maintenance/fee-schedule/add");
    },
    editFeeSchedule(id) {
      this.$router.push(`/system-maintenance/fee-schedule/edit/${id}`);
    },

    async toggleChangeStatus(id, isActive) {
      await this.fetchFeeSchedule(id);
      this.handleChangeStatusVisibility(true, id, isActive);
      this.handleSetSelectedFeeSchedule(id);
    },

    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },

    handleSetSelectedFeeSchedule(id) {
      this.viewSelectedFeeSched = this.feeSchedules.find(
        (feeSched) => feeSched.id === id
      );
      this.viewAssocType = this.viewSelectedFeeSched.type.label;
      if (this.viewAssociation.length >= 1) {
        this.changeStatusForm.isDisabled = true;
      } else {
        this.changeStatusForm.isDisabled = false;
      }
    },

    async resetStatusForm() {
      this.changeStatusForm.isDisabled = false;
      this.viewAssociation = [];
    },

    async onSubmitChangeStatus() {
      const { id, isActive, isDisabled } = this.changeStatusForm;

      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        try {
          const variables = {
            feeSchedId: {
              type: "UUID!",
              value: id,
              unincludeToFields: true,
            },
            limitData: ["success", "errors"],
            shouldNotReturnId: true,
          };
          const { success, errors } = await actions.updateFeeScheduleStatus(
            variables,
            isActive
          );
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Fee Schedule status.";
            throw errorMesssage;
          }

          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };

          await this.fetch();

          this.showNotifyMessage({
            message: `Fee Schedule ${isActive ? "deactivated" : "activated"}.`,
            type: "success",
          });
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },

    async fetchFeeSchedule(id) {
      try {
        let result = await actions.getFeeSchedule({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: id,
              },
            },
          },
          limitData: [
            "name",
            "feeId",
            "effectivityDate",
            this.buildSubQuery("associatedClients,", [
              "payorCode",
              "payorName",
            ]),
            this.buildSubQuery("associatedPayors,", [
              "name",
              "insuranceCompanyId",
            ]),
          ],
        });
        if (result) {
          let feeSchedModel = JSON.parse(
            JSON.stringify({
              ...result,
            })
          );
          const { associatedClients, associatedPayors } = feeSchedModel;
          this.viewAssociation = associatedClients
            ? [...associatedClients]
            : associatedPayors
            ? [...associatedPayors]
            : [];
        }
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },

    async onViewFeeSchedAssoc() {
      this.feeSchedAssocDialog = true;
    },

    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
